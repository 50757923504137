import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';
import { userInfo } from 'os';


@Injectable()
export class BBSDashbordService {

  constructor(private http: HttpClient) { }

  getDashboardSearchParams(dashboardFilter: any, param: Array<string> = []) {

    if (!!dashboardFilter) {

      if (!!dashboardFilter.DateRange) {
        // param.push("FromDate=" + moment(dashboardFilter.DateRange[0]).format('YYYY-MMM-DD'));
        // param.push("ToDate=" + moment(dashboardFilter.DateRange[1]).format('YYYY-MMM-DD 23:59:59'));
        param.push("FromDate=" + moment(dashboardFilter.DateRange.startDate).format('YYYY-MMM-DD 00:00:00'));
        param.push("ToDate=" + moment(dashboardFilter.DateRange.endDate).format('YYYY-MMM-DD 23:59:59'));

      } else {
        if (!!dashboardFilter.FromDate) {
          param.push("FromDate=" + moment(dashboardFilter.FromDate).format('YYYY-MMM-DD 00:00:00'));
        }
        if (!!dashboardFilter.ToDate) {
          param.push("ToDate=" + moment(dashboardFilter.ToDate).format('YYYY-MMM-DD 23:59:59'));
        }
      }
      // if (!!dashboardFilter.DateRange) {
      //   param.push("FromDate=" + moment(dashboardFilter.DateRange.startDate).format('YYYY-MMM-DD'));
      //   param.push("ToDate=" + moment(dashboardFilter.DateRange.endDate).format('YYYY-MMM-DD 23:59:59'));
      // }
      if (!!dashboardFilter.SelectedFacilityIds && dashboardFilter.SelectedFacilityIds.length > 0) {
        for (let index = 0; index < dashboardFilter.SelectedFacilityIds.length; index++) {
          param.push("SelectedFacilityIds=" + dashboardFilter.SelectedFacilityIds[index]);
        }
      }
      if (!!dashboardFilter.DashboardRight) {
        param.push("DashboardId=" + dashboardFilter.DashboardRight.Id);
        param.push("DashboardName=" + dashboardFilter.DashboardRight.Name);
        param.push("DashboardLevel=" + dashboardFilter.DashboardRight.Level);
      }
    }

    return param;
  }

  getMonthlySaftyObsCount(dashboardFilter, YearType: string) {
    let uri: string = "";
    let query = this.getDashboardSearchParams(dashboardFilter, ['YearType=' + YearType]);
    if (query.length > 0) {
      uri = "?" + query.join("&");
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/dashbord/MonthlySaftyObsCount' + uri);
  }

  getCategotySafeCount(dashboardFilter) {
    let uri: string = "";
    let query = this.getDashboardSearchParams(dashboardFilter);
    if (query.length > 0) {
      uri = "?" + query.join("&");
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/dashbord/CategorySafeAct' + uri);
  }

  getCategoryUnsafeCount(dashboardFilter) {
    let uri: string = "";
    let query = this.getDashboardSearchParams(dashboardFilter);
    if (query.length > 0) {
      uri = "?" + query.join("&");
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/dashbord/CategoryUnsafeAct' + uri);
  }

  getSeverityUnsafeCount(dashboardFilter) {
    let uri: string = "";
    let query = this.getDashboardSearchParams(dashboardFilter);
    if (query.length > 0) {
      uri = "?" + query.join("&");
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/dashbord/SeverityUnsafeCount' + uri);
  }

  getYearlySBOCount(dashboardFilter, YearType) {
    let uri: string = "";
    let query = this.getDashboardSearchParams(dashboardFilter, ['YearType=' + YearType]);
    if (query.length > 0) {
      uri = "?" + query.join("&");
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/dashbord/YearlySBO' + uri);
  }

  getCurrentUserRole() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/dashbord/role');
  }

  getObservation_Corporate(dashboardFilter) {
    let uri: string = "";
    let query = this.getDashboardSearchParams(dashboardFilter);
    if (query.length > 0) {
      uri = "?" + query.join("&");
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/corporate-dashbord/Observation' + uri);
  }

  getSafeObservation_Corporate(dashboardFilter) {
    let uri: string = "";
    let query = this.getDashboardSearchParams(dashboardFilter);
    if (query.length > 0) {
      uri = "?" + query.join("&");
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/corporate-dashbord/SafeObservation' + uri);
  }

  getUnsafeObservation_Corporate(dashboardFilter) {
    let uri: string = "";
    let query = this.getDashboardSearchParams(dashboardFilter);
    if (query.length > 0) {
      uri = "?" + query.join("&");
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/corporate-dashbord/UnsafeObservation' + uri);
  }

  getMonthlyObservation_Corporate(dashboardFilter, YearType: string) {
    let uri: string = "";
    let query = this.getDashboardSearchParams(dashboardFilter, ['YearType=' + YearType]);
    if (query.length > 0) {
      uri = "?" + query.join("&");
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/corporate-dashbord/Monthly-Observation' + uri);
  }

  getSafeVsUnsafeObservation_Corporate(dashboardFilter) {
    let uri: string = "";
    let query = this.getDashboardSearchParams(dashboardFilter);
    if (query.length > 0) {
      uri = "?" + query.join("&");
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/corporate-dashbord/Safe-vs-Unsafe-Observation' + uri);
  }


}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// BBS Action Type Service
@Injectable()
export class BBSActionTypeService {

    constructor(private http: HttpClient) { }

    // Get BBSTemplate
    getBBSActionType(page: Pagination = null) {
        let uri: string = "";
        if (!!page) {
            uri = page.GetUri();
        }
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/action-type' + uri);
    }


    // Get BBS Action Type
    getActionType(page: Pagination) {
        let uri: string = page.GetUri();
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/action-type' + uri);
    }

    // Edit BBS Action Type
    editBBSActionType(actionTypeId: number) {
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/action-type/' + actionTypeId);
    }

    // getActionTypeAuditTrail
    getActionTypeAuditTrail(actionTypeId: number) {
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/action-type/' + actionTypeId + '/audit-trail');
    }

    // Add BBS Action Type
    addBBSActionType(actionType: any) {
        return this.http.post<iApiResponse>(_$config.api_endpoint + 'bbs/action-type', actionType);
    }

    // Update Action type
    updateActionType(actionType: any) {
        return this.http.put<iApiResponse>(_$config.api_endpoint + 'bbs/action-type/' + actionType.ActionTypeId, actionType);
    }

    // Delete Action Type
    deleteActionType(actionTypeId: number) {
        return this.http.delete<iApiResponse>(_$config.api_endpoint + 'bbs/action-type/' + actionTypeId);
    }

}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';
import { Encryption } from '@utility/utility.encryption';

// Configuration Service
@Injectable()
export class ConfigurationService {

  constructor(private http: HttpClient) { }

  // Get timezones
  getTimezones() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'configuration/timezone');
  }

  // Get timezone by locations
  GetTimezoneByLocations() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'configuration/location/timezone');
  }

  // Save timezone by locations
  SaveTimezoneByLocations(locationConfigurations: Array<any>) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'configuration/location/timezone', locationConfigurations);
  }

  // getLDAP
  getLDAP() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'sso/ldap');
  }

  // updateLDAP
  updateLDAP(ldapConfig) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'sso/ldap', ldapConfig);
  }

  // testLDAP
  testLDAP(username, password) {
    let keyLevel1 = Encryption.Encrypt(username + ":" + password);
    let keyLevel2 = Encryption.Encrypt(keyLevel1);
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'sso/ldap/test', { Body: keyLevel2 });
  }

  // getSMTPConfig
  getSMTPConfig() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'smtp');
  }

  // updateSMTPConfig
  updateSMTPConfig(smtpConfig) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'smtp', smtpConfig);
  }

  // sendTestMail
  sendTestMail(smtpTestData) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'smtp/test-email', smtpTestData);
  }

}

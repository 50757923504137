import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// Contractor Service
@Injectable()
export class BBSObservationSubCategoryService {

  constructor(private http: HttpClient) { }

  // Get BBS Observation Sub Category
  getBBSObservationSubCategory(page: Pagination=null) {
    let uri: string = "";
    if(!!page){
      uri = page.GetUri();
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/observation-sub-category'+ uri);
  }

   // Edit BBS Observation Sub Category
   editBBSObservationSubCategory(observationSubCategoryId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/observation-sub-category/' + observationSubCategoryId);
  }

  // getObservationSubcategoryAuditTrail
  getObservationSubcategoryAuditTrail(observationSubCategoryId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/observation-sub-category/' + observationSubCategoryId + '/audit-trail');
  }

  // Add BBS Observation Sub Category
  addObservationSubCategory(observationSubCategory: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'bbs/observation-sub-category', observationSubCategory);
  }

  // Update Observation Sub Category
  updateObservationSubCategory(observationSubCategory: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'bbs/observation-sub-category/' + observationSubCategory.ObservationSubCategoryId, observationSubCategory);
  }

  // Delete Observation Sub Category
  deleteObservationSubCategory(observationSubCategoryId: number) {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'bbs/observation-sub-category/' + observationSubCategoryId);
  }
  
}
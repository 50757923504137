import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';

// Shortcu tLink Service
@Injectable()
export class ShortcutLinkService {

  constructor(private http: HttpClient) { }

  // Get Shortcut Links
  getShortcutLinks() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'shortcut-link');
  }

  // Get Shortcut Links
  updateShortcutLinks(links: Array<any>) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'shortcut-link', links);
  }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';
import { ApiUtility } from '@utility/utility.api-utility';
import { debugOutputAstAsTypeScript } from '@angular/compiler';


// Employee Service
@Injectable()
export class MasterDataService {

  constructor(private http: HttpClient) { }

  getMasterDataSheetByClient(fromDate, toDate, status) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'master-data/client?FromDate=' + fromDate + '&ToDate=' + toDate + '&Status=' + status);
  }

  updateRemark(masterDataSheetHistory, fileToUpload) {
    let input = new FormData();
    if (!!fileToUpload) {
      input.append("file", fileToUpload);
    }
    input.append("data", JSON.stringify(masterDataSheetHistory));
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'master-data/remark', input);
  }

  getMasterDataSheet() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'master-data');
  }

  getOnBoarding() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'specialized-service/onboarding');
  }

  getSupportTicket() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'specialized-service/support-ticket');
  }

  addSpecializedService(ssDetail) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'specialized-service', ssDetail);
  }

  getClientOnboarding() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'specialized-service/client-onboarding');
  }

  getClientSupportTicket() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'specialized-service/client-support-ticket');
  }

  uploadFile(fileToUpload: any) {
    let input = new FormData();
    input.append("file", fileToUpload);

    return this.http
      .post<iApiResponse>(_$config.api_endpoint + 'master-data/upload', input);
  }

  downloadSampleMasterData() {
    var api = 'master-data/sample'
    ApiUtility.downloadFile(api, 'mySetu SaaS Master Data Sheet.xlsx');
  }

  downloadFile(fileName, masterDataSheetId, masterDataSheetHistoryId = 0) {
    var api = masterDataSheetHistoryId > 0
      ?
      'master-data/' + masterDataSheetId + '/history/' + masterDataSheetHistoryId + '/download'
      :
      'master-data/' + masterDataSheetId + '/download'
      ;

    ApiUtility.downloadFile(api, fileName);
  }

}

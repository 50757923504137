import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// New Task Service
@Injectable()
export class TaskWorkFlowService {

    constructor(private http: HttpClient) { }

    // Get Work Flow
    getWorkFlow(page: Pagination = null) {
        let uri: string = "";
        if (!!page) {
            uri = page.GetUri();
        }
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'task/work-flow' + uri);
    }

    // Get Task Action
    getTaskAction(page: Pagination = null) {
        let uri: string = "";
        if (!!page) {
            uri = page.GetUri();
        }
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'task/action' + uri);
    }



    // Get Task Status
    getTaskStatus(page: Pagination = null) {
        let uri: string = "";
        if (!!page) {
          uri = page.GetUri();
        }
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'task/status' + uri);
    }

    // Edit Work Flow
    editWorkFlow(taskActionFlowId: number) {
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'task/work-flow/' + taskActionFlowId);
    }

    // getWorkFlowAuditTrail
    getWorkFlowAuditTrail(taskActionFlowId: number) {
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'task/work-flow/' + taskActionFlowId + '/audit-trail');
    }

    // Add Work Flow
    addWorkFlow(workFlow: any) {
        return this.http.post<iApiResponse>(_$config.api_endpoint + 'task/work-flow', workFlow);
    }

    // Update Work Flow
    updateWorkFlow(workFlow: any) {
        return this.http.put<iApiResponse>(_$config.api_endpoint + 'task/work-flow/' + workFlow.TaskActionFlowId, workFlow);
    }

    // Update Work Flow Status
    updateWorkFlowStatus(taskActionFlowId: number, isActive: boolean) {
        return this.http.put<iApiResponse>(_$config.api_endpoint + 'task/work-flow/' + taskActionFlowId + '/status?isActive=' + isActive, null);
    }

    // Delete Work Flow
    deleteWorkFlow(taskActionFlowId: number) {
        return this.http.delete<iApiResponse>(_$config.api_endpoint + 'task/work-flow/' + taskActionFlowId);
    }
}
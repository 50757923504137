import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// Contractor Service
@Injectable()
export class BBSTemplateService {

  constructor(private http: HttpClient) { }

  // Get BBSTemplate
  getBBSTemplate(isActiveDisplay: boolean = false) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbstemplate' + '?isActiveDisplay=' + isActiveDisplay);
  }

  // Get BBSTemplate Input Controls
  getBBSTemplateInputControls() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbstemplate/inputControl');
  }

  // Add BBSTemplate
  addBBSTemplate(bbstemplate: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'bbstemplate', bbstemplate);
  }


  // Update BBSTemplate
  updateBBSTemplate(bbstemplate: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'bbstemplate/' + bbstemplate.BBSTemplateId, bbstemplate);
  }

  UpdateHiddenTemlateFileds(TemlateId: number, TemlatefiledId: any, type) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'bbstemplate/updateHidden/' + TemlateId + '/' + type, TemlatefiledId);
  }

  // edit template field data by id
  editTemplateField(TemplateFieldId: number, isObservationField: boolean) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbstemplate/field/' + TemplateFieldId + '?isObservationField=' + isObservationField);
  }

  GetHidenRows(TemalteId: number, type) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbstemplate/GetHiddenRows/' + TemalteId + '/' + type);
  }

  // Add BBSTemplatefiled
  addBBSTemplateField(bbstemplatefield: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'bbstemplate/field', bbstemplatefield);
  }
  // Update BBSTemplate Field
  UpdateBBSTemplateField(TemplateFieldId: number, bbstemplatefield: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'bbstemplate/field/' + TemplateFieldId, bbstemplatefield);
  }

}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// Task Status Service
@Injectable()
export class TaskActionService {

  constructor(private http: HttpClient) { }

  // Get Task Action
  getTaskAction(page: Pagination = null) {
    let uri: string = "";
    if (!!page) {
      uri = page.GetUri();
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'task/action' + uri);
  }

  // Edit Task Action
  editTaskAction(taskActionId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'task/action/' + taskActionId);
  }

  // getTaskActionAuditTrail
  getTaskActionAuditTrail(taskActionId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'task/action/' + taskActionId + '/audit-trail');
  }


  // Add Task Status
  addTaskAction(taskAction: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'task/action', taskAction);
  }

  // Update Task Status
  updateTaskAction(taskAction: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'task/action/' + taskAction.TaskActionId, taskAction);
  }

  // Delete Task Status
  deleteTaskAction(taskActionId: number) {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'task/action/' + taskActionId);
  }
}